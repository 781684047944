import { Injectable } from '@angular/core';
import { Resolve, Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, DocumentSnapshot } from '@angular/fire/firestore';
import { Observable } from 'rxjs';

import { User } from '../models/user';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class UserResolver implements Resolve<User> {
  private uid: any;

  constructor(
    private authService: AuthService,
    private db: AngularFirestore,
    private afAuth: AngularFireAuth,
    private router: Router
  ) { }

  resolve(): Observable<User> {
    return new Observable(observer => {
      this.afAuth.onAuthStateChanged(currentUser => {
        if (currentUser) {
          this.db.collection('users').doc(currentUser.uid).get()
            .subscribe((d: DocumentSnapshot<User>) => {
              const user = d.data();
              this.authService.setCurrentUser(user);
              observer.next(user);
              observer.complete();

              if (!user.id) {
                this.router.navigate(['/login']);
              }
            });
        }
      });
    });
  }

}
