<mat-sidenav-container class="app-sidenav-container">
    <mat-sidenav #sidenav class="app-sidenav " [opened]="!isScreenSmall()" [mode]="isScreenSmall() ? 'over' : 'side'">
        <div class="app-sidenav-list">
            <h1 class="menu-text"> Menu</h1>
            <mat-nav-list>
                <!-- <a mat-list-item [routerLink]="['/customers']" routerLinkActive="active">
                    <mat-icon class="mat-icon-margin">people</mat-icon>
                    Kupci</a> -->
                <a mat-list-item [routerLink]="['/commercialists']" routerLinkActive="active">
                    <mat-icon class="mat-icon-margin">people_alt</mat-icon>Komercijalisti</a>
                <a mat-list-item [routerLink]="['/assortment']" routerLinkActive="active">
                    <mat-icon class="mat-icon-margin">assignment</mat-icon>Asortiman</a>
                <a mat-list-item [routerLink]="['/orders']" routerLinkActive="active">
                    <mat-icon class="mat-icon-margin scale">fact_check</mat-icon>Porudžbine</a>
                <!-- <a mat-list-item [routerLink]="['/returns']" routerLinkActive="active">
                    <mat-icon class="mat-icon-margin">assignment_return</mat-icon>Povrati</a> -->
            </mat-nav-list>
        </div>
        <div class="login-div">
            <button mat-icon-button class="login-button" color="primary" (click)="logout()"><mat-icon class="mat-icon-margin">logout</mat-icon>Izloguj se</button>
        </div>
    </mat-sidenav>
    <mat-sidenav-content>
        <mat-toolbar color="primary" class="toolbar-flex">
            <button mat-icon-button aria-label="Menu" class="sidenav-toggle" (click)="sidenav.toggle()">
                <mat-icon>menu</mat-icon>
            </button>
            <h3>mProcesses </h3>
        </mat-toolbar>
        <div class="wrapper">
            <router-outlet></router-outlet>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>