import { Injectable, NgZone } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { from, Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { CustomerAssortmentService } from 'src/app/assortments/services/customer-assortment.service';
import { OrdersService } from 'src/app/orders/services/orders.service';
import { User } from '../../core/models/user';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private user: User;

  constructor(
    public afs: AngularFirestore,
    public afAuth: AngularFireAuth,
    public router: Router,
    public ngZone: NgZone,
    private customerAssortmentService: CustomerAssortmentService,
    private ordersService: OrdersService
  ) {
  }

  logIn(email, password): Observable<any> {
    return from(this.afAuth.signInWithEmailAndPassword(email, password))
      .pipe(map(result => {
        return result;
      }), catchError(error => {
        return throwError(error);
      })
      );
  }

  signout() {
    return this.afAuth.signOut().then(() => {
      this.customerAssortmentService.customerAssortmentArticles = [];
      this.customerAssortmentService.customerAssortmentsMetadata = [];
      this.customerAssortmentService.customersCodes = [];
      this.ordersService.archivedOrders = [];
      this.ordersService.orders = [];
      this.router.navigate(['login']);
    });
  }

  getCurrentUser(): User {
    return this.user;
  }

  setCurrentUser(user: User) {
    this.user = user;
  }

}
