import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentSnapshot } from '@angular/fire/firestore';
import { from, Observable, throwError } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { User } from 'src/app/core/models/user';
import { CustomerOrder } from 'src/app/core/models/customerOrder';

@Injectable({
  providedIn: 'root'
})
export class OrdersService {

  lastVisible: Array<firebase.firestore.DocumentData> = [];
  firstVisible: Array<firebase.firestore.DocumentData> = [];

  isMarkedAsArchived: boolean = false;
  orders: CustomerOrder[] = [];
  archivedOrders: CustomerOrder[] = [];

  constructor(private db: AngularFirestore) {
  }

  getCollection(ref, queryFn, user: User): Observable<any[]> {
    return this.db.collection('organizations').doc(user.orgId).collection(ref, queryFn).snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const doc = a.payload.doc;
          return { ...data, doc };
        });
      }), catchError(err => throwError(err))
    );
  }

  get(user: User, pageSize: number): Observable<CustomerOrder[]> {
    return this.getCollection('inbound_orders', queryFn =>
      queryFn.where('isMarkedAsArchived', '==', this.isMarkedAsArchived).orderBy('requestedDeliveryDate', 'desc')
        .startAt(this.lastVisible)
        .limit(pageSize)
      , user)
      .pipe(map(data => {
        this.lastVisible = data[data.length - 1].doc;
        this.firstVisible = data[0].doc;
        data.map(d => delete d.doc)
        return data;
      })
      ).pipe(catchError(err => {
        this.createInitOrdersCollection(user);
        return [];
      }))
  }

  nextPage(user: User, pageSize: number): Observable<CustomerOrder[]> {
    return this.getCollection('inbound_orders', queryFn =>
      queryFn.where('isMarkedAsArchived', '==', this.isMarkedAsArchived).orderBy('requestedDeliveryDate', 'desc')
        .startAfter(this.lastVisible)
        .limit(pageSize)
      , user)
      .pipe(map(data => {
        this.lastVisible = data[data.length - 1].doc;
        this.firstVisible = data[0].doc;
        return this.sortData(data);
      }), catchError(() => {
        return [];
      }));
  }

  getOffsetData(user: User, pageSize: number): Observable<boolean> {
    return this.getCollection('inbound_orders', queryFn =>
      queryFn.where('isMarkedAsArchived', '==', this.isMarkedAsArchived).orderBy('requestedDeliveryDate', 'desc')
        .limit(pageSize).startAfter(this.lastVisible)
      , user)
      .pipe(map(data => {
        if (data.length) {
          return true
        } else {
          return false;
        }
      })
      );
  }

  previousPage(user: User, pageSize: number): Observable<CustomerOrder[]> {
    return this.getCollection('inbound_orders', queryFn =>
      queryFn.where('isMarkedAsArchived', '==', this.isMarkedAsArchived).orderBy('requestedDeliveryDate', 'desc')
        .endBefore(this.firstVisible)
        .limitToLast(pageSize)
      , user)
      .pipe(map(data => {
        this.lastVisible = data[data.length - 1].doc;
        this.firstVisible = data[0].doc;
        return this.sortData(data);
      }), catchError(() => {
        return [];
      }));
  }

  private sortData(data: any): any[] {
    let asSoonAsPosible = [];
    let otherDates = [];
    data.forEach(order => {
      if (order.deliverAsSoonAsPossible) {
        asSoonAsPosible.push(order);
      } else {
        otherDates.push(order)
      }
    })
    otherDates.sort((a, b) => (a.requestedDeliveryDate > b.requestedDeliveryDate) ? 1 : -1);
    otherDates.sort((a, b) => {
      if ((a.requestedDeliveryDate === b.requestedDeliveryDate) && a.orderRequestedEarlyDelivery && !b.orderRequestedEarlyDelivery) {
        return -1
      }
    });
    data = asSoonAsPosible.concat(otherDates)
    return data;
  }

  changeStatus(user: User, order: CustomerOrder): Observable<any> {
    const vendorOdrerRef = this.db.firestore.collection('organizations').doc(user.orgId).collection('inbound_orders').doc(order.id);
    const batch = this.db.firestore.batch();
    batch.update(vendorOdrerRef, { ...order });
    return from(batch.commit());
  }

  archiveOrder(user: User, order: CustomerOrder) {
    const date: Date = new Date()
    const vendorOdrerRef = this.db.firestore.collection('organizations').doc(user.orgId).collection('inbound_orders').doc(order.id);
    const batch = this.db.firestore.batch();
    batch.update(vendorOdrerRef, { isMarkedAsArchived: true, status: { code: "INVOICED", triggeredAtDate: date, triggeredByUserId: user.id, message: '' } });
    return from(batch.commit());
  }

  archiveOrders(user: User, orders: CustomerOrder[]) {
    const batch = this.db.firestore.batch();
    const vendorOdrerRef = this.db.firestore.collection('organizations').doc(user.orgId).collection('inbound_orders')
    orders.forEach(order => {
      batch.update(vendorOdrerRef.doc(order.id), { isMarkedAsArchived: true });
    })
    return from(batch.commit());
  }


  getCustomerOrder(user: User, orderId: string): Observable<CustomerOrder> {
    return this.db.collection('organizations').doc(user.orgId).collection('inbound_orders').doc(orderId).get().pipe(map((data: DocumentSnapshot<CustomerOrder>) => {
      return data.data();
    }
    ));
  }

  updateOrder(user: User, order: CustomerOrder): Observable<any> {
    const vendorOdrerRef = this.db.firestore.collection('organizations').doc(user.orgId).collection('inbound_orders').doc(order.id);
    const batch = this.db.firestore.batch();
    batch.update(vendorOdrerRef, { ...order });
    return from(batch.commit());
  }

  updateOrders(user: User, orders: CustomerOrder[]): Observable<any> {
    const batch = this.db.firestore.batch();
    orders.forEach(order => {
      const vendorOdrerRef = this.db.firestore.collection('organizations').doc(user.orgId).collection('inbound_orders').doc(order.id);
      batch.update(vendorOdrerRef, { notes: order.notes });
    })
    return from(batch.commit());
  }

  createInitOrdersCollection(user: User) {
    let vendorOrderEmpty: CustomerOrder = {}
    const odrer = this.db.firestore.collection('organizations').doc(user.orgId).collection('inbound_orders').doc();
    const odrer2 = this.db.collection('organizations').doc(user.orgId).collection('inbound_orders');
    odrer2.valueChanges(take(1)).subscribe(data => {
      if (!data.length) {
        const batch = this.db.firestore.batch();
        batch.set(odrer, { ...vendorOrderEmpty })
        batch.commit()
      }
    })
  }

  getVehicles(user: User) {
    return this.db.collection('organizations').doc(user.orgId).collection('vehicles').get().pipe(map(data => {
      let vehiclesArray: [][] = [];
      vehiclesArray = data.docs.map(doc => doc.data().vehicles);
      return vehiclesArray[0];
    }
    ));
  }
}
