import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'src/app/core/guards/auth.guard';
import { ShellComponent } from './core/components/shell/shell.component';
import { UserResolver } from './core/resolvers/user.resolver';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    component: ShellComponent,
    resolve: [UserResolver],
    children: [
      { path: '', redirectTo: '/orders', pathMatch: 'full' },
      {
        path: 'customers',
        loadChildren: () => import('./customers/customers.module').then(m => m.CustomersModule)
      },
      {
        path: 'commercialists',
        loadChildren: () => import('./commercialists/commercialists.module').then(m => m.CommercialistsModule)
      },
      {
        path: 'assortment',
        loadChildren: () => import('./assortments/assortments.module').then(m => m.AssortmentsModule)
      },
      {
        path: 'orders',
        loadChildren: () => import('./orders/orders.module').then(m => m.OrdersModule)
      },
      {
        path: 'returns',
        loadChildren: () => import('./returns/returns.module').then(m => m.ReturnsModule)
      },
    ]
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./register/register.module').then(m => m.RegisterModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
