import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentSnapshot } from '@angular/fire/firestore';
import { Observable, from, of } from 'rxjs';
import { catchError, map, take, tap } from 'rxjs/operators';
import { Article } from 'src/app/core/models/article';
import { Assortment } from 'src/app/core/models/assortment';
import { OrderRequest } from 'src/app/core/models/orderRequest';
import { User } from 'src/app/core/models/user';

@Injectable({
  providedIn: 'root'
})
export class CustomerAssortmentService {


  customerAssortmentArticles: Article[] = [];
  customerAssortmentsMetadata: any = [];
  customersCodes: string[] = [];

  lastVisible: Array<firebase.firestore.DocumentData> = [];
  firstVisible: Array<firebase.firestore.DocumentData> = [];
  offsetData: Array<firebase.firestore.DocumentData> = [];

  constructor(private db: AngularFirestore) { }

  getAssortments(user: User): Observable<any> {
    return this.db.collection('vendor_subcatalogs').doc<Assortment[]>(user.id).get();
  }

  getAssortmentArticles(user: User, id: string): Observable<any> {
    return this.db.collection('vendor_subcatalogs').doc(user.id).collection(id).doc(id).get();
  }

  // Get all articles by filter params: same code as in articles service 
  getArticlesByCode(user: User, reqParams): Observable<Article[]> {
    return this.db.collection('catalogs').doc(user.id).collection(user.catalogForInternalUsers, ref => {
      let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
      query = query.where('code', '==', reqParams.filterParam);
      return query;
    }).valueChanges().pipe(take(1));
  }
  getArticlesByName(user: User, reqParams): Observable<Article[]> {
    return this.db.collection('catalogs').doc(user.id).collection(user.catalogForInternalUsers, ref => {
      let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
      query = query.where('name', '>=', reqParams.filterParam).where('name', '<=', reqParams.filterParam + '\uf8ff');
      return query;
    }).valueChanges().pipe(take(1));
  }

  saveInitMetadata(user: User) {
    this.db.collection('vendor_subcatalogs').doc(user.id).set({ metadata: [] })
  }

  save(user: User, assortment: Assortment): Observable<any> {
    const articles: Article[] = this.customerAssortmentArticles;
    const newCollectionId = this.db.createId();
    assortment.id = newCollectionId;
    this.customerAssortmentsMetadata.metadata.push(assortment);
    const assortmentMetadata = this.db.firestore.collection('vendor_subcatalogs').doc(user.id);
    const assortmentArticles = this.db.firestore.collection('vendor_subcatalogs').doc(user.id).collection(newCollectionId).doc(newCollectionId);
    const batch = this.db.firestore.batch();
    batch.set(assortmentArticles, { articles });
    batch.set(assortmentMetadata, { ...this.customerAssortmentsMetadata });
    this.customerAssortmentArticles = [];
    return from(batch.commit());
  }

  updateAssortmentMetadata(user: User, assortment: Assortment, metadata: any): Observable<any> {
    this.customerAssortmentsMetadata.metadata = metadata;
    this.customerAssortmentsMetadata.metadata.push(assortment);
    const assortmentMetadataDB = this.db.collection('vendor_subcatalogs').doc(user.id);
    return from(assortmentMetadataDB.update({ ...this.customerAssortmentsMetadata }));
  }

  updateAssortmentArticlesList(user: User, assortment: Assortment, articles: Article[]) {
    this.customerAssortmentsMetadata.metadata.push(assortment);
    const assortmentMetadata = this.db.firestore.collection('vendor_subcatalogs').doc(user.id);
    const assortmentArticles = this.db.firestore.collection('vendor_subcatalogs').doc(user.id).collection(assortment.id).doc(assortment.id);
    const batch = this.db.firestore.batch();
    batch.set(assortmentArticles, { articles });
    batch.set(assortmentMetadata, { ...this.customerAssortmentsMetadata });
    return from(batch.commit());
  }

  delete(user: User, assortment: Assortment): Observable<any> {
    this.customerAssortmentsMetadata.metadata = this.customerAssortmentsMetadata.metadata.filter(assort => assort !== assortment);
    const assortmentMetadata = this.db.firestore.collection('vendor_subcatalogs').doc(user.id);
    const assortmentArticles = this.db.firestore.collection('vendor_subcatalogs').doc(user.id).collection(assortment.id).doc(assortment.id);
    const batch = this.db.firestore.batch();
    batch.set(assortmentMetadata, { ...this.customerAssortmentsMetadata });
    batch.delete(assortmentArticles);
    return from(batch.commit());
  }

  // asortiman -stari pristup
  getCustomersCodes(user: User): Observable<any> {
    return this.db.collection('forced_catalogs').doc(user.id).collection('userCode').get();
  }

  // Stari pristup
  saveCustomerAssortment(user: User, products: Article[], customerCode: string): Observable<any> {
    const catalogRef = this.db.firestore.collection('forced_catalogs').doc(user.id).collection('userCode').doc(customerCode);
    const batch = this.db.firestore.batch();
    batch.set(catalogRef, { products });
    return from(batch.commit());
  }

  deleteAssortment(user: User, customerCode: string): Observable<any> {
    let products: Article[] = [];
    const catalogRef = this.db.firestore.collection('forced_catalogs').doc(user.id).collection('userCode').doc(customerCode);
    const batch = this.db.firestore.batch();
    batch.delete(catalogRef);
    return from(batch.commit());
  }

  getCustomerAssortmentArticles(user: User, customerCode: string): Observable<any> {
    return this.db.collection('forced_catalogs').doc(user.id).collection('userCode').doc(customerCode).get().pipe(map(data => {
      return data.data().products;
    }
    ))
  }

  updateAssortment(user: User, customerCode: string, products: Article[]): Observable<any> {
    const catalogRef = this.db.firestore.collection('forced_catalogs').doc(user.id).collection('userCode').doc(customerCode);
    const batch = this.db.firestore.batch();
    batch.update(catalogRef, { products });
    return from(batch.commit());
  }

  addAssortmentArticles(user: User, customerCode: string, articles: Article[]) {
    return this.updateAssortment(user, customerCode, articles);
  }

  deleteArticle(user: User, customerCode: string, articles: Article[]) {
    return this.updateAssortment(user, customerCode, articles);
  }

}
