import { Component, OnInit, NgZone, OnChanges } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Location } from '@angular/common';
import { AuthService } from '../../services/auth.service';

// Brakepoint je podesen. Promenuti vrednost u ts i css (media) ako bude bilo potrebno.
const SMALL_WIDTH_BREAKPOINT = 9000;
@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss']
})
export class ShellComponent implements OnInit, OnChanges {

  private mediaMatcher: MediaQueryList =
    matchMedia(`(max-width: ${SMALL_WIDTH_BREAKPOINT}px)`);

  url: string;

  constructor(private authService: AuthService, private location: Location, zone: NgZone, private router: Router) {
    this.mediaMatcher.addListener(mql =>
      zone.run(() => this.mediaMatcher = matchMedia(`(max-width: ${SMALL_WIDTH_BREAKPOINT}px)`)));
  }

  ngOnInit(): void {
    // this.url = this.router.url;
    // this.location.onUrlChange(url => this.url = url)
  }

  ngOnChanges(): void {

  }

  logout(): void {
    this.authService.signout();
  }
  isScreenSmall(): boolean {
    return this.mediaMatcher.matches;
  }

}
