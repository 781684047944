// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  env: 'local',
  swEnable: false,
  firebase: {
    /* apiKey: "AIzaSyDGXPpqqRyiMvxyiXN_PUain3RY_6MUCCE",
    authDomain: "mprocesses-fb-prod.firebaseapp.com",
    projectId: "mprocesses-fb-prod",
    storageBucket: "mprocesses-fb-prod.appspot.com",
    messagingSenderId: "1079770724232",
    appId: "1:1079770724232:web:cdc12bd11b5229a4b36c3d",
    measurementId: "G-1T2MWG06YJ" */
    apiKey: "AIzaSyDpSZgMhM6dJIOqbCpFXfo2G1GjMmJkTMU",
    authDomain: "mprocesses-fb-stage.firebaseapp.com",
    projectId: "mprocesses-fb-stage",
    storageBucket: "mprocesses-fb-stage.appspot.com",
    messagingSenderId: "739250452179",
    appId: "1:739250452179:web:697836432ea6d50ec44626",
    measurementId: "G-4LXPP54BYS"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
